








































































import {Component, Vue} from "vue-property-decorator";
import {getModule} from "vuex-module-decorators";
import {ValidationObserver} from "vee-validate";
import UserStore from "@//Store/Modules/UserStore";

@Component
export default class Login extends Vue {
    userStore: UserStore = getModule(UserStore, this.$store);
    $refs!: {
        form: InstanceType<typeof ValidationObserver>;
    };
    title: string = process.env.VUE_APP_TITLE as string;
    alertMessage: string|null = null;
    valid: boolean = false;
    loading: boolean = false;
    email: string = ((process.env.NODE_ENV === 'development') ? 'admin@example.com' : '');
    password: string = ((process.env.NODE_ENV === 'development') ? 'admin' : '');
    tfa_required: boolean = false;
    tfa: string|null = null;

    async mounted() {
        if (this.$route.params.data === 'registered') {
            this.alertMessage = 'Account has been created successfully. Please check your email to activate your account.';
        } else if (this.$route.params.data !== undefined) {
            this.loading = true;
            // await UserApi.verify({
            //     token: this.$route.params.data
            // }).then(() => {
            //     this.alertMessage = 'Your account has been verified.';
            //     this.loading = false;
            // }).catch((error) => {
            //     if (error.response.status === 422) {
            //         this.alertMessage = error.response.data.errors.token[0];
            //     } else {
            //         this.alertMessage = 'Something went wrong while verifying your email. Please try again later.';
            //     }
            //     this.loading = false;
            // });
        }
    }

    login() {
        this.loading = true;
        this.$refs.form.validate().then((valid) => {
            if (!valid) {
                this.loading = false;
                return;
            }

            this.userStore.login({
                email: this.email,
                password: this.password,
                tfa: this.tfa
            }).then(() => {
                this.userStore.updateUserInfo().then(() => {
                    this.$root.$emit('loggedIn');
                    this.$router.push('/');
                });
            }).catch((error: any) => {
                this.loading = false;
                this.tfa_required = error.response.data.errors.tfa !== undefined;
                this.$refs.form.setErrors(error.response.data.errors);
            });
        })
    }
}
